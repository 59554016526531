
import { Component, Prop, Vue } from "vue-property-decorator";

type Tile = {
  name: string;
};

@Component
export default class BusinessCustomerComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;
}
